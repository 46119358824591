<template>
  <div class="content-right">
    <div class="right-word">
      <h3>Account Settings</h3>
      <div class="content-name">
        <div class="content-user">Avatar</div>
        <div class="right-avatar" @click="openEmail">
          <ImageUpload
            v-model:imgUrl="state.avatarImg"
            mode="avatar"
            :limit="1"
            @update:imgUrl="updateimgUrl"
          >
          </ImageUpload>
        </div>
      </div>
      <div class="content-name">
        <div class="content-user">Username</div>
        <div style="width:250px; text-align: center; white-space: nowrap;overflow: hidden; text-overflow: ellipsis;" >{{ state.userInfo.nickname }}</div>
        <div class="right" @click="checkUsername">Edit</div>
      </div>

      <!-- <div class="content-name">
                <div class="content-user">Email</div>
                <div>{{ state.userInfo.email }}</div>
                <div class="right" @click="openEmail">Edit</div>
            </div> -->

      <!-- <div class="content-name">
                <div class="content-user">Verification</div>
                <div class="right">Send Verification E-mail</div>
            </div> -->

      <div class="content-name">
        <div class="content-user">Password</div>
        <div>******</div>
        <div class="right" @click="checkPassword">Edit</div>
      </div>
    </div>

    <el-dialog v-model="dialogFormVisible" title="Reset password" width="600px">
      <el-form
        :model="form"
        ref="passWordFromRef"
        label-width="150px"
        :rules="passWordRules"
      >
        <el-form-item label="Old password:" label-width="150px" prop="oldPwd">
          <el-input v-model="form.oldPwd" autocomplete="off" show-password />
        </el-form-item>
        <el-form-item label="New password:" label-width="150px" prop="newPwd">
          <el-input v-model="form.newPwd" autocomplete="off" show-password clearable />
        </el-form-item>
        <el-form-item label="Confirm password:" label-width="150px" prop="checkPassword">
          <el-input
            v-model="form.checkPassword"
            autocomplete="off"
            show-password
            clearable
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">Cancel</el-button>
          <el-button
            type="primary"
            @click="confirm"
            :loading="passLoading"
            :disabled="passDisable"
          >
            Confirm modification
          </el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog
      v-model="dialogUsername"
      title="Reset Username"
      width="30%"
      :before-close="handleClose"
    >
      <div>
        <el-input v-model="newUsername" />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogUsername = false">Cancel</el-button>
          <el-button type="primary" @click="checkUsernameOk"> Confirm </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { updatePassWord, updateAvatar } from "@/api/sys.js";
import ImageUpload from "@/components/ImageUpload";
import request from "@/utils/request.js";
import bus from "vue3-eventbus";

const dialogUsername = ref(false);
const passWordFromRef = ref(null);
const dialogFormVisible = ref(false);
const passLoading = ref(false);
const passDisable = ref(false);
const state = reactive({
  userInfo: {},
  avatarImg: "",
});
const form = ref({
  oldPwd: "",
  newPwd: "",
  checkPassword: "",
});

const newUsername = ref("");

const checkpass = (rule, value, callback) => {
  if (value == form.value.newPwd) {
    callback();
  } else {
    callback(new Error("Password inconsistency"));
  }
};
const validatePass = (rule, value, callback) => {
  const pwdReg = /^(?=.*\d)(?=.*[a-zA-Z]).{6,}$/;
  if (!value) {
    return callback(new Error("Please enter the password"));
  } else if (pwdReg.test(value)) {
    callback();
  } else {
    callback(new Error("Please enter at least 6 characters in English plus numbers"));
  }
};

const passWordRules = ref({
  oldPwd: [
    { required: true, message: "Please enter your old password", trigger: "blur" },
    { validator: validatePass, trigger: "blur" },
  ],
  newPwd: [
    { required: true, message: "Please enter your new password", trigger: "blur" },
    { validator: validatePass, trigger: "blur" },
  ],
  checkPassword: [
    { required: true, message: "Cannot be empty", trigger: "blur" },
    { validator: checkpass, trigger: "blur" },
  ],
});
onMounted(() => {
  getInfoMation();
  requestUserInfo();
});

const checkPassword = () => {
  dialogFormVisible.value = true;
};

const checkUsername = () => {
  dialogUsername.value = true;
};

//dialogUsername.value = false
newUsername;

const checkUsernameOk = () => {
  if (!newUsername.value) return;
  let obj = {
    ...state.userInfo,
    nickname: newUsername.value,
  };
  updateAvatar(obj).then((res) => {
    if (res.code == 200) {
      requestUserInfo();
      bus.emit("getUser","1")
      ElMessage({
        message: "The user nickname is successfully modified",
        type: "success",
      });
      dialogUsername.value = false;
    } else {
      ElMessage({
        message: res.msg,
        type: "warning",
      });
    }
  });
  console.log(obj, "state.userInfo");
};

// 请求用户信息
async function requestUserInfo() {
  const { code, data } = await request.get("/system/FaUser/info");
  if (code == 200) {
    // 储存用户信息
    // store.commit('setUserInfo', JSON.stringify(data))
    state.userInfo = data;
    state.avatarImg = state.userInfo.avatar;
    console.log("state.userInfo11", state.userInfo);
    // console.log(da.name, "ppp");
    console.log(data, "data");
  }
}
const getInfoMation = () => {
  state.userInfo = JSON.parse(localStorage.getItem("userInfo"));
  console.log(state.userInfo, "state.userInfo");
  state.avatarImg = state.userInfo.avatar;
};
//修改密码
const confirm = () => {
  passWordFromRef.value.validate((valid) => {
    if (valid) {
      if (form.value.oldPwd == form.value.newPwd) {
        ElMessage({
          message: "The old and new passwords are the same.",
          type: "warning",
        });
        return;
      }
      passLoading.value = true;
      passDisable.value = true;
      const params = {
        id: state.userInfo.id,
        oldPwd: form.value.oldPwd,
        newPwd: form.value.newPwd,
      };
      updatePassWord(params).then((res) => {
        if (res.code == 200) {
          passWordFromRef.value.resetFields();
          dialogFormVisible.value = false;
          passLoading.value = false;
          passDisable.value = false;
          ElMessage({
            message: "Password changed successfully",
            type: "success",
          });
        } else {
          ElMessage({
            message: "Failed to change the password",
            type: "warning",
          });
          passLoading.value = false;
          passDisable.value = false;
        }
      });
    } else {
    }
  });
};
//修改会员头像
const updateimgUrl = (e) => {
  console.log(e, "touxian");
  const param = { id: state.userInfo.id, avatar: e };
  updateAvatar(param).then((res) => {
    if (res.code == 200) {
      requestUserInfo();
      ElMessage({
        message: "Succeeded in modifying the profile picture",
        type: "success",
      });
    } else {
      ElMessage({
        message: res.msg,
        type: "success",
      });
    }
  });
};
</script>
<style lang="scss" scoped>
.content-right {
  width: 550px;
  background-color: rgba(0, 0, 0, 0.3);

  .right-word {
    padding: 22px;

    h3 {
      line-height: 50px;
      color: #ffff;
    }

    .content-name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 50px;
      color: #ffff;
      border-bottom: 1px solid #5a5e5f;
      font-size: 14px;

      .content-user {
        margin-right: 60px;
        font-size: 16px;
      }

      .right-avatar {
      }

      .right {
        color: #c4e93d;
        margin-left: 50px;
        cursor: pointer;
      }
    }
  }
}

.el-input__wrapper {
  width: 100px;
}

.el-form-item {
  width: 450px;
}
</style>
